/* Thanks to: https://scastiel.dev/3dbook */

.bookContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 600px;
  height: 170px;
}

.bookContainerBackground {
  background-image: url("https://res.cloudinary.com/dstgp4faa/image/upload/c_crop,h_1055,w_1287/c_scale,w_379/v1698755722/leiturascristas/assets/photo-1516541196182-6bdb0516ed27_rsh0i1.jpg");
  background-size: cover;
}

@keyframes initAnimation {
  0% {
    transform: rotateY(0deg) scale(0.7);
  }
  100% {
    transform: rotateY(-30deg) scale(0.7);
  }
}

.book {
  width: 125px;
  height: 175px;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateY(-30deg) scale(0.7);
  transition: 0.5s ease;
  animation: 1s ease 0s 1 initAnimation;
}

.bookContainer:hover .book,
.bookContainer:focus .book {
  transform: rotateY(0deg) scale(0.85);
}

.book > :first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 125px;
  height: 175px;
  transform: translateZ(5px);
  background-color: #b9b9b9;
  border-radius: 0 2px 2px 0;
  box-shadow: 5px 5px 20px #66666645;
}

.book::before {
  position: absolute;
  content: " ";
  background-color: blue;
  left: 0;
  top: 0px;
  width: 8px;
  height: 175px;
  transform: translateX(120px) rotateY(90deg);
  background: linear-gradient(
    90deg,
    #fff 0%,
    #f9f9f9 5%,
    #fff 10%,
    #f9f9f9 15%,
    #fff 20%,
    #f9f9f9 25%,
    #fff 30%,
    #f9f9f9 35%,
    #fff 40%,
    #f9f9f9 45%,
    #fff 50%,
    #f9f9f9 55%,
    #fff 60%,
    #f9f9f9 65%,
    #fff 70%,
    #f9f9f9 75%,
    #fff 80%,
    #f9f9f9 85%,
    #fff 90%,
    #f9f9f9 95%,
    #fff 100%
  );
}

.book::after {
  position: absolute;
  top: 0;
  left: 0;
  content: " ";
  width: 125px;
  height: 175px;
  transform: translateZ(-5px);
  background-color: #4a4a4a;
  border-radius: 0 2px 2px 0;
  box-shadow: -10px 0 50px 10px #66666645;
}
